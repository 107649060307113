import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Typography, IconButton } from '@mui/material';
import { Facebook, Instagram, LinkedIn, YouTube, X } from '@mui/icons-material';

// const TikTokIcon = ({ color = "#ffffff", fontSize = 'default' }) => {
//   const size = fontSize === 'small' ? 20 : fontSize === 'large' ? 35 : 24; // default size is 24
//   return (
//     <svg
//       fill={color}
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 50 50"
//       width={size}
//       height={size}
//     >
//       <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
//     </svg>
//   );
// };

const Footer = () => {
  return (
    <Box 
    bgcolor="#000"
    padding="30px 0"
    >
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="#444"
            p={1}
            borderRadius="10px"
          >
            <IconButton color="inherit" href="https://twitter.com/volitarestudios" sx={{color: 'white'}}>
              <X />
            </IconButton>
            <IconButton color="inherit" href="https://www.facebook.com/profile.php?id=61557516525219" sx={{color: 'white'}}>
              <Facebook />
            </IconButton>
            <IconButton color="inherit" href="https://www.instagram.com/volitarestudios/" sx={{color: 'white'}}>
              <Instagram />
            </IconButton>
            <IconButton color="inherit" href="https://www.youtube.com/@volitarestudios" sx={{color: 'white'}}>
              <YouTube />
            </IconButton>
            {/* <IconButton color="inherit" href="https://www.tiktok.com/@volitarestudios" sx={{color: 'white'}}>
              <TikTokIcon />
            </IconButton> */}
            <IconButton color="inherit" href="https://www.linkedin.com/company/100962775/" sx={{color: 'white'}}>
              <LinkedIn />
            </IconButton>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <Typography
            variant="body2"
            color="white"
            component={Link}
            to="/privacy-policy"
            sx={{
              fontFamily:'Baskic8',
              textDecoration: 'none',
              color: 'white',
              marginRight: '10px',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Privacy
          </Typography>
          <Typography variant="body2" color="white" sx={{ fontFamily:'Baskic8' }}>
            | © {new Date().getFullYear()} Volitare Studios. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
