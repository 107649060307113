import React from 'react';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import './styles/BlogCard.css';
import { Link } from 'react-router-dom';

const BlogCard = ({ post }) => {
  const { data, excerpt, rest } = post; 
  // data might include: { title, date, author, image }

  return (
    <motion.div
      className="blog-card"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.02 }}
    >
      {/* Optional image header (only if front matter includes `image`) */}
      {data.image && (
        <div className="blog-card-image">
          <img src={data.image} alt={""} />
        </div>
      )}

      <div className="blog-card-content">
        <h2 className="blog-card-title">{data.title}</h2>

        <div className="blog-card-meta">
          {/* Show author and date if provided */}
          {data.author && <span className="blog-card-author">{data.author}</span>}
          {data.date && <span className="blog-card-date">{data.date}</span>}
        </div>

        <div className="blog-card-body">
          <ReactMarkdown>{excerpt}</ReactMarkdown>
        </div>

        <div className="blog-card-footer">
            {/* If there's additional content, show a "Read More" link */}
            {rest && rest.length > 0 && (
                <Link to={`/blog/${post.data.slug}`} className="read-more">
                  Read More
                </Link>
              )}
        </div>
      </div>
    </motion.div>
  );
};

export default BlogCard;
