import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './styles/Careers.css';
import backgroundImage from '../assets/nebula4k.jpg';
import BlogCard from '../components/BlogCard';
// import matter from 'gray-matter';

const Blog = () => {
  // State to hold the text of each Markdown file
  const [posts, setPosts] = useState([]);

  function splitContentAtDelimiter(content) {
    const DELIMITER = '<!-- more -->';
    const index = content.indexOf(DELIMITER);
  
    if (index !== -1) {
      const excerpt = content.slice(0, index).trim();
      const rest = content.slice(index + DELIMITER.length).trim();
      return { excerpt, rest };
    }
    // If no delimiter found, treat the entire content as the excerpt
    return { excerpt: content, rest: '' };
  }

  function parseFrontMatter(markdown) {
    // Remove possible BOM at the start of the file
    markdown = markdown.replace(/^\uFEFF/, '');
  
    // Regex that allows \r?\n (CRLF or LF) and handles optional whitespace
    const frontMatterRegex = /^---\r?\n([\s\S]*?)\r?\n---\r?\n([\s\S]*)$/;
  
    const match = frontMatterRegex.exec(markdown);
    if (!match) {
      // No front matter found
      return { data: {}, content: markdown };
    }
  
    const frontMatter = match[1];
    const content = match[2];
  
    const data = {};
    frontMatter.split('\n').forEach(line => {
      const [key, ...rest] = line.split(':');
      if (key && rest.length) {
        data[key.trim()] = rest.join(':').trim().replace(/^"|"$/g, '');
      }
    });
  
    return { data, content };
  }  

  useEffect(() => {
    const mdFiles = [
      '/posts/first-post.md',
      '/posts/gamevolt-first-look.md',
    ];

    const fetchMarkdownFiles = async () => {
      const loadedPosts = [];
      for (const filePath of mdFiles) {
        const response = await fetch(filePath);
        if (!response.ok) {
          console.error(`Failed to load ${filePath}`);
          continue;
        }
        const raw = await response.text();
        // Parse front matter with gray-matter
        const { data, content } = parseFrontMatter(raw);
        console.log('Parsed front matter:', data);
        console.log('Parsed content:', content);

        // Split content at the delimiter
        const { excerpt, rest } = splitContentAtDelimiter(content);

        loadedPosts.push({ data, excerpt, rest });
      }
      // Sort by date descending (optional)
      loadedPosts.sort((a, b) => new Date(b.data.date) - new Date(a.data.date));
      setPosts(loadedPosts);
    };

    fetchMarkdownFiles();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh', // This makes sure the content takes up at least the full height of the viewport
        alignItems: 'center',
        color: 'white',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
        // backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F55%2Fe2%2Fb2%2F55e2b2171897530365e1d28ed4f5bbd9.png&f=1&nofb=1&ipt=c9b1c631861c240f22201fcb7b9ca90b2abca85bfc3e945e88db237f0aa04130&ipo=images")', /* Replace with your image path */
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1, // This makes the div take up all available space, pushing the footer to the bottom
      }}>
        <h1 style={{fontSize: '2em', textAlign: 'center', fontFamily: 'Baskic8' }}>Blog</h1>
        {/* <p style={{fontSize: '1.5em', textAlign: 'center', fontFamily: 'Baskic8' }}>This page is currently undergoing construction!
          <br></br>Come back soon to see the new changes.</p> */}
        <div
        style={{
          width: '90%',
          maxWidth: '800px',
          // maxHeight: '70vh',
          // overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          padding: '20px',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          borderRadius: '8px',
          marginBottom: '40px', // Space above any footer
        }}
      >
        {posts.map((post, index) => (
          <BlogCard key={index} post={post} />
        ))}
      </div>
      </div>
    </motion.div>
  );
};

export default Blog;
