// src/pages/PostPage.js
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import backgroundImage from '../assets/nebula4k.jpg';
import './styles/Careers.css';
import '../components/styles/BlogCard.css'; // Reuse the same card styles

function PostPage() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  function splitContentAtDelimiter(content) {
    const DELIMITER = '<!-- more -->';
    const index = content.indexOf(DELIMITER);

    if (index !== -1) {
      const excerpt = content.slice(0, index).trim();
      const rest = content.slice(index + DELIMITER.length).trim();
      return { excerpt, rest };
    }
    // If no delimiter found, treat entire content as excerpt
    return { excerpt: content, rest: '' };
  }

  function parseFrontMatter(markdown) {
    // Remove possible BOM at the start
    markdown = markdown.replace(/^\uFEFF/, '');
    // Regex that handles Windows/Unix line endings
    const frontMatterRegex = /^---\r?\n([\s\S]*?)\r?\n---\r?\n([\s\S]*)$/;

    const match = frontMatterRegex.exec(markdown);
    if (!match) {
      // No front matter found
      return { data: {}, content: markdown };
    }

    const frontMatter = match[1];
    const content = match[2];

    const data = {};
    frontMatter.split('\n').forEach(line => {
      const [key, ...rest] = line.split(':');
      if (key && rest.length) {
        data[key.trim()] = rest.join(':').trim().replace(/^"|"$/g, '');
      }
    });

    return { data, content };
  }

  useEffect(() => {
    const filePath = `/posts/${slug}.md`;

    const fetchPost = async () => {
      try {
        const response = await fetch(filePath);
        if (!response.ok) throw new Error('Post not found');

        const raw = await response.text();
        const parsed = parseFrontMatter(raw);

        // Split content at the <!-- more --> delimiter
        const { excerpt, rest } = splitContentAtDelimiter(parsed.content);
        parsed.excerpt = excerpt;
        parsed.rest = rest;

        setPost(parsed);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPost();
  }, [slug]);

  if (!post) {
    return <div>Loading...</div>;
  }

  const { data } = post;
  const fullContent = post.excerpt + '\n\n' + post.rest;

  return (
    <motion.div
      className="blog-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        minHeight: '100vh',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {/* A container to center the card */}
      <div
        className="blog-list"
        style={{
          margin: 'auto', 
          width: '90%',
          maxWidth: '800px',
          padding: '2rem'
        }}
      >
        <div className="blog-card">
          {/* Optional image header (only if front matter includes `image`) */}
          {data.image && (
            <div className="blog-card-image">
              <img src={data.image} alt={data.title || 'Post image'} />
            </div>
          )}

          <div className="blog-card-content">
            <h2 className="blog-card-title">{data.title}</h2>

            <div className="blog-card-meta">
              {/* Show author and date if provided */}
              {data.author && <span className="blog-card-author">By {data.author}</span>}
              {data.date && <span className="blog-card-date">{data.date}</span>}
            </div>

            <div className="blog-card-body">
              <ReactMarkdown>{fullContent}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default PostPage;
